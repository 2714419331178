import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Head from 'next/head';
import { Theme } from '@radix-ui/themes';
import { SessionProvider } from 'next-auth/react';
import { DM_Sans } from 'next/font/google';
import '@radix-ui/themes/styles.css';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { Toaster } from '@/components/ui/Toaster';
import { toast } from '@/hooks/useToast';
import { useMemo } from 'react';
config.autoAddCss = false;

// Initialize DM Sans font
const dmSans = DM_Sans({
  subsets: ['latin'],
  weight: ['300', '400', '500', '700'],
  variable: '--font-dm-sans'
});

// Dynamically import ReactQueryDevtools
const ReactQueryDevtools = dynamic(() => process.env.NODE_ENV === 'development' ? import('@tanstack/react-query-devtools').then(mod => mod.ReactQueryDevtools) : Promise.resolve(() => null), {
  ssr: false
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  // Use useMemo to prevent recreating the client on every render
  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5,
        // 5 minutes
        retry: 1,
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      onError: (error: Error, query) => {
        if (query.meta?.suppressErrorToast) {
          return;
        }
        toast({
          variant: 'destructive',
          title: 'Error',
          description: query.meta?.errorMessage as string || error.message || 'An error occurred'
        });
      }
    })
  }), []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>Basalt Health</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={`${dmSans.variable} font-sans`}>
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <SessionProvider session={pageProps.session} data-sentry-element="SessionProvider" data-sentry-source-file="_app.tsx">
            <Theme radius="large" accentColor="indigo" scaling="100%" data-sentry-element="Theme" data-sentry-source-file="_app.tsx">
              <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
              <Toaster data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
            </Theme>
          </SessionProvider>
          <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
        </QueryClientProvider>
      </main>
    </>;
}
export default MyApp;